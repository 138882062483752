import logo from './logo.svg';
import './App.css';
import WeatherApp from './components/weather';
import { BrowserRouter , Route, Routes, Link } from 'react-router-dom';

function App() {
  return (
    <div className="App">
     <BrowserRouter>
     <Routes>
      <Route path="/" element={<WeatherApp></WeatherApp>}>

      </Route>
     </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;
