import React, { useState, useEffect } from 'react';
import { Search, MapPin, Cloud, Thermometer, Droplet, Wind } from 'lucide-react';
import {ACCUWEATHER_API_KEY} from '../apiKey';
// Note: You'll need to replace this with your actual AccuWeather API key

const WeatherApp = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [currentWeather, setCurrentWeather] = useState(null);
  const [forecast, setForecast] = useState(null);
  const [error, setError] = useState(null);

  // Autocomplete location search
  const searchLocations = async (query) => {
    if (query.length < 3) return;

    try {
      const response = await fetch(
        `https://dataservice.accuweather.com/locations/v1/cities/autocomplete?apikey=${ACCUWEATHER_API_KEY}&q=${query}`
      );
      const data = await response.json();
     
      setLocationSuggestions(data);
    } catch (err) {
      setError('Error finding locations');
    }
  };

  // Get current conditions
  const getCurrentWeather = async (locationKey) => {
    try {
      const response = await fetch(
        `https://dataservice.accuweather.com/currentconditions/v1/${locationKey}?apikey=${ACCUWEATHER_API_KEY}`
      );
      const data = await response.json();
      console.log("curretn weather",data[0]);
      setCurrentWeather(data[0]);
    } catch (err) {
      setError('Error fetching current weather');
    }
  };

  // Get 5-day forecast
  const getForecast = async (locationKey) => {
    try {
      const response = await fetch(
        `https://dataservice.accuweather.com/forecasts/v1/daily/5day/${locationKey}?apikey=${ACCUWEATHER_API_KEY}`
      );
      const data = await response.json();
      console.log("days:",data)
      setForecast(data.DailyForecasts);
    } catch (err) {
      setError('Error fetching forecast');
    }
  };

  // Handle location selection
  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
    setSearchTerm(location.LocalizedName);
    setLocationSuggestions([]);
    
    // Fetch weather data
    getCurrentWeather(location.Key);
    getForecast(location.Key);
  };

  // Debounce search input
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchTerm) {
        searchLocations(searchTerm);
      }
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchTerm]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-400 to-blue-600 p-4 text-white">
      <div className="container mx-auto max-w-md">
        {/* Search Bar */}
        <div className="relative mb-6">
          <div className="flex items-center bg-white/20 rounded-full p-2">
            <Search className="text-white mr-2" />
            <input
              type="text"
              placeholder="Search city or location"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="bg-transparent w-full text-white placeholder-white/70 focus:outline-none"
            />
          </div>

          {/* Autocomplete Suggestions */}
          {locationSuggestions.length > 0 && (
            <ul className="absolute z-10 w-full bg-white/90 rounded-lg shadow-lg mt-1 max-h-64 overflow-y-auto">
              {locationSuggestions.map((location) => (
                <li 
                  key={location.Key}
                  onClick={() => handleLocationSelect(location)}
                  className="p-2 hover:bg-blue-100 cursor-pointer text-blue-800"
                >
                  {location.LocalizedName}, {location.Country.LocalizedName}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Weather Display */}
        {selectedLocation && currentWeather && (
          <div className="text-center">
            <h2 className="text-2xl font-bold flex items-center justify-center">
              <MapPin className="mr-2" />
              {selectedLocation.LocalizedName}, {selectedLocation.Country.LocalizedName}
            </h2>

            <div className="mt-6 bg-white/20 rounded-xl p-6">
              <div className="flex justify-center items-center mb-4">
                <Cloud className="text-5xl mr-4" />
                <div>
                  <h3 className="text-4xl font-bold">
                    {currentWeather.Temperature.Metric.Value}°C
                  </h3>
                  <p>{currentWeather.WeatherText}</p>
                </div>
              </div>

              {/* Additional Weather Details */}
              <div className="grid grid-cols-3 gap-4 mt-4">
                <div className="flex flex-col items-center">
                  <Thermometer />
                  <span>Today is</span>
                  <strong>{currentWeather.WeatherText}</strong>
                  </div>
                <div className="flex flex-col items-center">
                  <Droplet />
                  <span>Humidity</span>
                  <strong>{currentWeather.RelativeHumidity}%</strong>
                </div>
                <div className="flex flex-col items-center">
                  <Wind />
                  <span>Wind</span>
                  {/* <strong>{currentWeather.Wind.Speed.Imperial.Value} mph</strong> */}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* 5-Day Forecast */}
        {forecast && (
          <div className="mt-6 bg-white/20 rounded-xl p-4">
            <h3 className="text-xl font-bold mb-4 text-center">5-Day Forecast</h3>
            <div className="grid grid-cols-5 gap-2">
              {forecast.map((day, index) => (
                <div key={index} className="text-center">
                  <p className="font-semibold">
                    {new Date(day.Date).toLocaleDateString('en-US', { weekday: 'short' })}
                  </p>
                  <div className="flex flex-col items-center">
                    <span>{day.Temperature.Maximum.Value}°F</span>
                    <span className="text-sm text-white/70">{day.Temperature.Minimum.Value}°F</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Error Handling */}
        {error && (
          <div className="mt-4 bg-red-500/50 p-3 rounded-lg text-center">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default WeatherApp;